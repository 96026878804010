/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TagPojoGen } from './tag-pojo.gen.model';


export interface SrActionClientPojoGen { 
    actionObjetId?: number;
    actionObjetInstanceLabel?: string;
    actionTypeId?: number;
    actionTypeInstanceLabel?: string;
    besoins?: string;
    clientId?: number;
    clientInstanceLabel?: string;
    commentaire?: string;
    commercialId?: number;
    commercialInstanceLabel?: string;
    contexte?: string;
    created?: Date;
    createdBy?: string;
    date?: Date;
    devisStatut?: string;
    duree?: string;
    dureeTypeId?: string;
    dureeTypeInstanceLabel?: string;
    dureeValue?: number;
    entites?: string;
    entitesexport?: string;
    entitesid?: string;
    id?: number;
    instanceLabel?: string;
    montant?: number;
    prochaineEtape?: string;
    proposition?: string;
    tags?: Array<TagPojoGen>;
    tagsexport?: string;
    tagsid?: string;
    updated?: Date;
    updatedBy?: string;
    versionNum?: number;
}

export class SrActionClientPojoGen_ {
  static readonly actionObjetId = 'actionObjetId';
  readonly actionObjetId = 'actionObjetId';
  static readonly actionObjetInstanceLabel = 'actionObjetInstanceLabel';
  readonly actionObjetInstanceLabel = 'actionObjetInstanceLabel';
  static readonly actionTypeId = 'actionTypeId';
  readonly actionTypeId = 'actionTypeId';
  static readonly actionTypeInstanceLabel = 'actionTypeInstanceLabel';
  readonly actionTypeInstanceLabel = 'actionTypeInstanceLabel';
  static readonly besoins = 'besoins';
  readonly besoins = 'besoins';
  static readonly clientId = 'clientId';
  readonly clientId = 'clientId';
  static readonly clientInstanceLabel = 'clientInstanceLabel';
  readonly clientInstanceLabel = 'clientInstanceLabel';
  static readonly commentaire = 'commentaire';
  readonly commentaire = 'commentaire';
  static readonly commercialId = 'commercialId';
  readonly commercialId = 'commercialId';
  static readonly commercialInstanceLabel = 'commercialInstanceLabel';
  readonly commercialInstanceLabel = 'commercialInstanceLabel';
  static readonly contexte = 'contexte';
  readonly contexte = 'contexte';
  static readonly created = 'created';
  readonly created = 'created';
  static readonly createdBy = 'createdBy';
  readonly createdBy = 'createdBy';
  static readonly date = 'date';
  readonly date = 'date';
  static readonly devisStatut = 'devisStatut';
  readonly devisStatut = 'devisStatut';
  static readonly duree = 'duree';
  readonly duree = 'duree';
  static readonly dureeTypeId = 'dureeTypeId';
  readonly dureeTypeId = 'dureeTypeId';
  static readonly dureeTypeInstanceLabel = 'dureeTypeInstanceLabel';
  readonly dureeTypeInstanceLabel = 'dureeTypeInstanceLabel';
  static readonly dureeValue = 'dureeValue';
  readonly dureeValue = 'dureeValue';
  static readonly entites = 'entites';
  readonly entites = 'entites';
  static readonly entitesexport = 'entitesexport';
  readonly entitesexport = 'entitesexport';
  static readonly entitesid = 'entitesid';
  readonly entitesid = 'entitesid';
  static readonly id = 'id';
  readonly id = 'id';
  static readonly instanceLabel = 'instanceLabel';
  readonly instanceLabel = 'instanceLabel';
  static readonly montant = 'montant';
  readonly montant = 'montant';
  static readonly prochaineEtape = 'prochaineEtape';
  readonly prochaineEtape = 'prochaineEtape';
  static readonly proposition = 'proposition';
  readonly proposition = 'proposition';
  static readonly tags = 'tags';
  readonly tags = 'tags';
  static readonly tagsexport = 'tagsexport';
  readonly tagsexport = 'tagsexport';
  static readonly tagsid = 'tagsid';
  readonly tagsid = 'tagsid';
  static readonly updated = 'updated';
  readonly updated = 'updated';
  static readonly updatedBy = 'updatedBy';
  readonly updatedBy = 'updatedBy';
  static readonly versionNum = 'versionNum';
  readonly versionNum = 'versionNum';
  static fields() {
    return new SrActionClientPojoGen_();
  }
}

